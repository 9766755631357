import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss']
})
export class PublicationsComponent implements OnInit {
  pdfSrc;
  items: any[] = [
    {id: "assets/document/Newsletter-July 2024-Episode-6.pdf", name:"Episode 6: Newsletter July 2024"},
    {id: "assets/document/Newsletter-January 2024-Episode-5.pdf", name:"Episode 5: Newsletter January 2024"},
    {id: "assets/document/Annual Report-2023.pdf", name:"Annual Report 2023"},
    {id: "assets/document/Newsletter-July 2023-Episode-4.pdf", name:"Episode 4: Newsletter July 2023"},
    {id: "assets/document/Newsletter-January 2023-Episode-3.pdf", name:"Episode 3: Newsletter January 2023"},
    {id: "assets/document/Annual Report-2022.pdf", name:"Annual Report 2022"},
    {id: "assets/document/Newsletter-July 2022-Episode-2.pdf", name:"Episode 2: Newsletter July 2022"},
    {id: "assets/document/Newsletter-January 2022-Episode-1.pdf", name:"Episode 1: Newsletter January 2022"},
    {id: "assets/document/Annual Report-2021.pdf", name:"Annual Report 2021"},

  ];
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.items[0].id);
  }

onSelected(value:string){
    this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

}
