<section class="py-20 bg-gray-300">
  <div class="container mx-auto">
<!--    <div class="flex flex-wrap md:mt-32 md:p-16 p-8">-->
<!--      <div class="w-full rounded-lg max-h-96 overflow-hidden mb-6 flex justify-center items-center">-->
<!--        <img width=100% src="assets/image/events/summerpicnic2024.jpg"/>-->
<!--      </div>-->

<!--      <h3 class="text-3xl mb-2 font-semibold leading-normal">-->
<!--        Upcoming Event: Summer Picnic Celebration 2024-->
<!--      </h3>-->
<!--      <div class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">-->
<!--        As the warmth of summer signals, it's time for us to come together and celebrate the joys of community gathering at our Summer Picnic! As we gather for a day filled with delicious food, exciting games, and entertainment for all ages.-->

<!--        <br/><br/>-->
<!--        Please register at the earliest to save your spot.-->
<!--        <div class="text-center md:text-left my-3">-->
<!--          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdGiJLIjUilo5m4qxooAmuoFwUifCQ8f_XyOrjoYsMrqrqKDw/viewform" target="_blank">-->
<!--            <button-->
<!--              class="bg-red-700 text-white active:bg-gray-700 text-sm font-bold uppercase px-10 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"-->
<!--              type="button"-->
<!--              style="transition: all 0.15s ease 0s;"-->
<!--            >-->
<!--              Register Now-->
<!--            </button>-->
<!--          </a>-->
<!--        </div>-->

<!--        <div class="font-semibold">Program details:</div>-->
<!--        <span class="font-semibold">Date:</span> Sunday, June 23, 2024-->
<!--        <br/>-->
<!--        <span class="font-semibold">Time: </span>9 am to 8 pm<br/>-->
<!--        <br/>-->
<!--        <div class="font-semibold">Venue:</div>-->
<!--        <a href="https://maps.app.goo.gl/Zb7STA4YfckYFGpu8" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">-->
<!--          <i class="fa-solid fa-location-dot"></i> Edworthy Park, Site # 9, 5050 Spruce Dr SW, Calgary, AB T3C 3B2<br/>-->
<!--        </a>-->

<!--        <br/>-->

<!--        <div class="font-semibold">Registration Fees:</div>-->
<!--        NSC Members: <span class="font-semibold">$25</span>-->
<!--        <br/>-->
<!--        Non-Members: <span class="font-semibold">$30</span>-->
<!--        <br/>-->
<!--        Students / Kids (6-13 yrs): <span class="font-semibold">$15</span>-->
<!--        <br/>-->
<!--        Kids (under 6 yrs): <span class="font-semibold">Free</span>-->
<!--        <br/>-->
<!--        Seniors (65 yrs and above): <span class="font-semibold">Free</span>-->
<!--        <br/><br/>-->


<!--        Please send e-transfer to: <span class="font-semibold">thenewahcalgary@gmail.com</span>-->
<!--        <br/><br/>-->

<!--        Looking forward to seeing you all.-->
<!--        <br/>-->
<!--        Subhaay!-->
<!--      </div>-->



<!--    </div>-->

<!--    <div class="flex flex-wrap mt-8 md:p-16 p-8">-->
<!--      <div class="w-full rounded-lg max-h-96 overflow-hidden mb-6 flex justify-center items-center">-->
<!--        <img width=100% src="https://cdn-az.allevents.in/events2/banners/9681c0e5611a93ea1a2e18e8f7a29dac24a4c4506830a67817f46a2cc82fe86f-rimg-w1080-h400-dcd23226-gmir?v=1717896332"/>-->
<!--      </div>-->

<!--      <h3 class="text-3xl mb-2 font-semibold leading-normal">-->
<!--        Upcoming Event: Nepali Mela Calgary 2024-->
<!--      </h3>-->
<!--      <div class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">-->
<!--        NCSC, along with several Nepalese organizations and functional and ethnic groups in collaboration with racialized communities in Calgary, is hosting the second episode of the Nepali Mela Calgary -NMMS (Nepali Mela and Multicultural Showcasing ) Event on July 20, 2024. To make the event successful and memorable for all visitors and organizers, we need helping hands, expertise, and interests from diverse walks of life. Please come forward and help us conclude the event conjointly.-->
<!--        <br/>-->
<!--        <div class="font-semibold">Program details:</div>-->
<!--        <span class="font-semibold">Date:</span> Saturday, July 20, 2024-->
<!--        <br/>-->
<!--        <span class="font-semibold">Time: </span>All day<br/>-->
<!--        <br/>-->
<!--        <div class="font-semibold">Venue:</div>-->
<!--        <a href="https://maps.app.goo.gl/gjQ827foxs8jmn91A" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">-->
<!--          <i class="fa-solid fa-location-dot"></i> Prairie Winds Park,223 Castleridge Blvd NE, Calgary, AB T3J 1P6<br/>-->
<!--        </a>-->
<!--        <br/>-->
<!--        Looking forward to seeing you all.-->
<!--        <br/>-->
<!--        Subhaay!-->
<!--      </div>-->
<!--    </div>-->

<!--  <div class="flex flex-wrap mt-8 md:p-16 p-8">-->
<!--    <div class="w-full rounded-lg max-h-96 overflow-hidden mb-6 flex justify-center items-center">-->
<!--      <img width=100% src="assets/image/events/mhapujabanner.jpg"/>-->
<!--    </div>-->

<!--    <h3 class="text-3xl mb-2 font-semibold leading-normal">-->
<!--      Upcoming Event: Mhapuja 2024 & Nepal Sambat 1145 New Year Celebration-->
<!--    </h3>-->
<!--    <div class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">-->
<!--      It is our immense pleasure to inform that Newah Society of Calgary (NSC) is continuing to organize the most awaited event "Mhapuja 2024 & Nepal Sambat 1145 New Year Celebration" this year as well in collaboration with Calgary Nepalese Community Association (CNCA) and Nepalese Community Society of Calgary (NCSC).-->
<!--      <br/>-->
<!--      <div class="font-semibold">Program Details:</div>-->
<!--      <span class="font-semibold">Venue:</span> <a href="https://g.co/kgs/Uh3qwSj" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank"> Crossroad Community Association (1803 14 Ave NE, Calgary, AB T2E 1G4)</a>-->
<!--      <br/>-->
<!--      <span class="font-semibold">Date:</span> Saturday, November 2, 2024-->
<!--      <br/>-->
<!--      <span class="font-semibold">Time: </span>5pm Onwards<br/>-->
<!--      5:30 - 6:30 pm:  Mhapuja and snacks<br/>-->
<!--      6:45 - 7:30 pm:  Cultural Music Program<br/>-->
<!--      8:00 pm: Main Course<br/><br/>-->
<!--      <span class="font-semibold">Registration Fees:</span><br/>-->
<!--      Youths and adults (14 - 64 years):  $25<br/>-->
<!--      Students / Kids (6-13 yrs): $15<br/>-->
<!--      Kids (under 6 yrs): Free<br/>-->
<!--      Seniors (65 yrs and above): Free<br/>-->
<!--      <br/>-->
<!--      <span class="font-semibold">An additional of $5 per person to participate in Mhapuja ceremony</span>-->
<!--      <br/>-->
<!--      Please send e-transfer to: <I>thenewahcalgary@gmail.com</I>-->

<!--      <br/>-->
<!--     <div class="text-center mt-2">-->
<!--              <a href="https://docs.google.com/forms/d/e/1FAIpQLScU0KDGweMHGw2ij22dovk8uxgL5DPOMVFPiEXBoPYJr1ER5A/viewform" target="_blank">-->
<!--                      <button-->
<!--                        class="bg-red-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"-->
<!--                        type="button"-->
<!--                        style="transition: all 0.15s ease 0s;"-->
<!--                      >-->
<!--                        Register Now-->
<!--                      </button>-->
<!--                    </a>-->
<!--    </div>-->
<!--      <br/>-->
<!--      Looking forward to seeing you all.-->
<!--      <br/>-->
<!--      Subhaay!-->
<!--    </div>-->
<!--  </div>-->

    <div class="flex flex-wrap mt-8 md:p-16 p-8">
      <div class="w-full rounded-lg max-h-full overflow-hidden mb-6 flex justify-center items-center">
        <img width=30% src="assets/image/events/Poster-November%2027,%202024-1.jpg"/>
        <img width=40% src="assets/image/events/yomari_pune.jpg"/>
        <img width=30% src="assets/image/events/Poster-November%2027,%202024-2.jpg"/>
      </div>

      <h3 class="text-3xl mb-2 font-semibold leading-normal">
        Upcoming Event: Yomari & Samay Baji with Musical Extravaganza!!
      </h3>
      <div class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
        We are excited to invite you to the Yomari Punhi Musical Night, a special cultural celebration organized in collaboration with Nepali ethnic groups and community organizations.
        <br>
        The objective of the Yomari Punhi Musical Night, organized by the Newah Society of Calgary, is to promote, preserve, and strengthen Nepali music, foster continuous engagement among singers, and educate and inspire the younger generation in Calgary to appreciate and participate in Nepali musical traditions.
        <br/><br/>
        <div class="font-semibold">Program Details:</div>
        <span class="font-semibold">Singers:</span> Anu Shrestha Mathema, Buddhi Adhikari, Pramila Chhetri Rai, Yubraj Gurung, Rockaatma Band and others <br/>
        <span class="font-semibold">Venue:</span> <a href="https://maps.app.goo.gl/iCMDRrxfRJfCZ2Ut5" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank"> Falconridge Castleridge Community Association, 95 Falshire Dr NE, Calgary, AB, T3J 1P7</a>
        <br/>
        <span class="font-semibold">Date:</span> Saturday, December 14, 2024
        <br/>
        <span class="font-semibold">Time: </span>5pm Onwards<br/>
        5:00 - 6:00 pm:  Meal (Newari Samaybaji set (covered by the registration fee))<br/>
        6:00 - 9:00 pm:  Music Program<br/><br/>
        <span class="font-semibold">Registration Fees:</span><br/>
        Youths and adults (14 - 64 years):  $25<br/>
        Students / Kids (6-13 yrs): $15<br/>
        Kids (under 6 yrs): Free<br/>
        Seniors (65 yrs and above): Free<br/>
        <br/>
        <span class="font-semibold">An additional of $5 per person for on spot registration.</span>
        <br/>
        Please send e-transfer to: <I>thenewahcalgary@gmail.com</I>

        <br/>
        <div class="text-center mt-2">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfoG5bGzZlJqlR8JPIDDe6tUmxMdU1jwjJ6xNxKym3krP1nlQ/viewform" target="_blank">
            <button
              class="bg-red-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style="transition: all 0.15s ease 0s;"
            >
              Register Now
            </button>
          </a>
        </div>
        <br/>
        Looking forward to seeing you all.
        <br/>
        Subhaay!
      </div>
    </div>
  </div>
</section>
