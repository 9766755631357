import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {UpcomingEventsComponent} from './upcoming-events/upcoming-events.component';
import {PastEventsComponent} from './past-events/past-events.component';


const routes: Routes = [
  {path: 'upcoming-events', component: UpcomingEventsComponent},
  {path: 'past-events', component: PastEventsComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventsRoutingModule { }
