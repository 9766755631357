<section class="py-20 bg-gray-300">
  <div class="container mx-auto">
    <div class="flex flex-wrap md:mt-32 md:p-16 p-4">
      <h2 class="text-3xl mb-2 font-semibold leading-normal mb-10">
        Past Events of 2024
      </h2>

    <object [data]='pdfSrc'  type="application/pdf" width="100%" height="800px">
      It appears you don't have a PDF plugin for this browser. You can <a [href]="pdfSrc" class="font-bold underline"> click here to download the PDF file.</a>
    </object>
    </div>
  </div>
</section>
