import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  leaderCommittee = [];
  advisoryCommittee= [];
  constructor() { }

  ngOnInit(): void {
    this.leaderCommittee = [
      {
        name: 'Mr. Hemanta Maharjan',
        designation: 'President',
        imageUrl: 'assets/image/people/Hemanta-6.jpg'
      },
      {
        name: 'Mr. Arun Shrestha',
        designation: 'Vice President',
        imageUrl: 'assets/image/people/Arun.jpg'
      },
      {
        name: 'Mr. Raju Shrestha',
        designation: 'General Secretary',
        imageUrl: 'assets/image/people/Raju.jpg'
      },
      {
        name: 'Mrs. Roshi Shrestha',
        designation: 'Treasurer',
        imageUrl: 'assets/image/people/roshi.jpg'
      },
      {
        name: 'Mr. Tejash Shrestha',
        designation: 'Joint Secretary',
        imageUrl: 'assets/image/people/tejash.jpg'
      },
      {
        name: 'Ms. Shirisha Maharjan',
        designation: 'Director of Language, Nepal Bhasa School and Literature',
        imageUrl: 'assets/image/people/siri.jpg'
      },
      {
        name: 'Mrs. Pooja Shrestha',
        designation: 'Director of Events and Fund Raising',
        imageUrl: 'assets/image/people/pooja.jpg'
      }, {
        name: 'Mr. Suman Singh Maharjan',
        designation: 'Director of Newah Arts, Culture, Tradition, and Rituals',
        imageUrl: 'assets/image/people/suman.jpg'
      },
      {
        name: 'Mr. Rabi Shankar Talchabhadel',
        designation: 'Director of Membership, Communications, and Information Technology',
        imageUrl: 'assets/image/people/rabi.jpg'
      },
    ];

    this.advisoryCommittee = [
      {
        name: 'Mr. Purushottam Shrestha',
        imageUrl: 'assets/image/people/Purushottam.jpg'
      },
      {
        name: 'Mr. Jagadish Kayastha',
        imageUrl: 'assets/image/people/Jagadish.jpg'
      },
      {
        name: 'Mr. Laxman Shrestha',
        imageUrl: 'assets/image/people/Laxman.jpg'
      },
      {
        name: 'Mrs. Sunanda Shrestha',
        imageUrl: 'assets/image/people/Sunanda.jpg'
      }
    ];
  }

}
