<nav class="flex flex-wrap items-center justify-between bg-red-600 p-4 mt-0 fixed w-full z-10 top-0 hover:text-gray-700 focus:text-gray-700 navbar navbar-expand-lg">
  <div class="container-fluid w-full flex flex-wrap items-center justify-between">

    <button class="
    navbar-toggler
    text-gray-500
    border-0
    hover:shadow-none hover:no-underline
    py-2
    px-2.5
    bg-transparent
    focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline
  " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation" (click)="closeNav()">
        <div class="flex justify-left md:justify-start px-8">
          <a href="/"><img src="./assets/image/logos/newah-header-bw.png" alt="" class="logo-collapse float-right h-12" ></a>
        </div>
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars"
               class="w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor"
                  d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
            </path>
          </svg>
    </button>

    <div class="collapse navbar-collapse flex-grow items-center" id="navbarSupportedContent" [attr.aria-expanded]="!isCollapsed" [ngClass]="{'show': isCollapsed}">
      <div class="collapse navbar-collapse flex w-full md:w-1/3 justify-center md:justify-start px-8">
        <a href="/"><img src="./assets/image/logos/newah-header-bw.png" alt="" width="200"></a>
      </div>
      <!-- Left links -->
      <ul class="navbar-nav flex flex-col pl-0 list-style-none mr-auto w-full justify-end">
        <li (click)="closeNav()" class="nav-item pr-2">
          <a routerLink="/" class="block md:inline-block text-white hover:text-red-200 px-3 py-3 border-red-900 md:border-none active:bg-blue-600">Home</a>
        </li>
        <li class="nav-item pr-2">
          <div class="dropdown inline-block nav-link">
            <span class="block md:inline-block text-white hover:text-red-200 px-3 py-3 border-red-900 md:border-none active:bg-blue-600">About <i class="fa-solid fa-angle-down"></i></span>
            <ul (click)="closeNav()" class="dropdown-menu absolute hidden text-gray-700 pt-2">
              <li class=""><a class="rounded-t bg-gray-200 hover:bg-red-400 hover:text-black text-left py-2 px-4 pr-12 block whitespace-no-wrap" routerLink="/about">Organization</a></li>
              <li class=""><a class="rounded-b bg-gray-200 hover:bg-red-400 hover:text-black text-left py-2 px-4 pr-12 block whitespace-no-wrap" routerLink="/civilization">Newah Civilization</a></li>
              <li class=""><a class="rounded-b bg-gray-200 hover:bg-red-400 hover:text-black text-left py-2 px-4 pr-12 block whitespace-no-wrap" routerLink="/nation">Newah as a Nation</a></li>
            </ul>
          </div>
        </li>
<!--        <li class="nav-item pr-2">-->
<!--          <div class="dropdown inline-block">-->
<!--            <span class="block md:inline-block text-white hover:text-red-200 px-3 py-3 border-red-900 md:border-none">Get Involved  <i class="fa-solid fa-angle-down"></i></span>-->
<!--            <ul class="dropdown-menu absolute hidden text-gray-700 pt-2">-->
<!--              <li class=""><a class="rounded-t bg-gray-200 hover:bg-red-400 hover:text-black text-left py-2 px-4 pr-12 block whitespace-no-wrap" routerLink="/volunteer">Volunteer</a></li>-->
<!--              <li class=""><a class="bg-gray-200 hover:bg-red-400 hover:text-black text-left py-2 px-4 pr-12 block whitespace-no-wrap" routerLink="/membership">Membership</a></li>-->
<!--&lt;!&ndash;              <li class=""><a class="rounded-b bg-gray-200 hover:bg-red-400 hover:text-black text-left py-2 px-4 pr-12 block whitespace-no-wrap" routerLink="donate">Donate</a></li>&ndash;&gt;-->
<!--            </ul>-->
<!--          </div>-->
<!--        </li>-->
        <li class="nav-item pr-2">
          <div class="dropdown inline-block">
            <span class="block md:inline-block text-white hover:text-red-200 px-3 py-3 border-red-900 md:border-none">Programs  <i class="fa-solid fa-angle-down"></i></span>
            <ul (click)="closeNav()" class="dropdown-menu absolute hidden text-gray-700 pt-2">
              <li class=""><a class="rounded-t bg-gray-200 hover:bg-red-400 hover:text-black text-left py-2 px-4 pr-12 block whitespace-no-wrap" routerLink="/language">Language</a></li>
<!--              <li class=""><a class="bg-gray-200 hover:bg-red-400 hover:text-black text-left py-2 px-4 pr-12 block whitespace-no-wrap" routerLink="/research">Research</a></li>-->
              <li class=""><a class="rounded-b bg-gray-200 hover:bg-red-400 hover:text-black text-left py-2 px-4 pr-12 block whitespace-no-wrap" routerLink="/publications">Publications</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item pr-2">
          <div class="dropdown inline-block">
            <span class="block md:inline-block text-white hover:text-red-200 px-3 py-3 border-red-900 md:border-none">Gallery <i class="fa-solid fa-angle-down"></i></span>
            <ul (click)="closeNav()" class="dropdown-menu absolute hidden text-gray-700 pt-2">
              <li class=""><a class="rounded-t bg-gray-200 hover:bg-red-400 hover:text-black text-left py-2 px-4 pr-12 block whitespace-no-wrap" routerLink="/photos">Photos</a></li>
<!--              <li class=""><a class="rounded-b bg-gray-200 hover:bg-red-400 hover:text-black text-left py-2 px-4 pr-12 block whitespace-no-wrap" routerLink="/videos">Videos</a></li>-->
            </ul>
          </div>
        </li>
        <li class="nav-item pr-2">
          <div class="dropdown inline-block">
            <span class="block md:inline-block text-white hover:text-red-200 px-3 py-3 border-red-900 md:border-none">Events <i class="fa-solid fa-angle-down"></i></span>
            <ul class="dropdown-menu absolute hidden text-gray-700 pt-2">

              <li class=""><a class="rounded-t bg-gray-200 hover:bg-red-400 hover:text-black text-left py-2 px-4 pr-12 block whitespace-no-wrap" routerLink="/events/upcoming-events">Upcoming Events</a></li>
              <li class=""><a class="rounded-b bg-gray-200 hover:bg-red-400 hover:text-black text-left py-2 px-4 pr-12 block whitespace-no-wrap" routerLink="/events/past-events">Past Events</a></li>
            </ul>
          </div>
        </li>
        <li (click)="closeNav()" class="nav-item pr-2">
          <a href="#" class="block md:inline-block text-white hover:text-red-200 px-3 py-3 border-red-900 md:border-none" routerLink="/contact">Contact</a>
        </li>
      </ul>
      <!-- Left links -->
    </div>
    <!-- Collapsible wrapper -->
  </div>
</nav>
