<main>
  <!--  Top Header, contains image carousel and text-->
  <div class="images relative pt-16 pb-32 flex content-center items-center justify-center">
    <div class="images-center absolute top-0 w-full h-full bg-center bg-cover">
      <span id="blackOverlay" class="w-full h-full absolute opacity-40 bg-black"></span>

      <picture>
        <source srcset="assets/image/large/background-2.jpg" media="(min-width: 1000px)">
        <source srcset="assets/image/large/d2-1.jpg" media="(min-width: 700px)">
        <img
          src="assets/image/large/d1-1.jpg"
          alt="Nepal"
        />
      </picture>
    </div>

  <div class="container relative mx-auto">
    <div class="items-center flex flex-wrap">
      <div class="w-full lg:w-6/12 px-4 pt-32 ml-auto mr-auto shadow-sm text-center">
        <div class="">
          <h1 class="text-white font-semibold text-5xl"> HERE TO CELEBRATE </h1>
          <h3 class="mt-4 font-bold text-lg text-gray-300"> CULTURE • LANGUAGE • HERITAGE </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
       style="height: 70px; transform: translateZ(0px);">
    <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
         viewBox="0 0 2560 100" x="0" y="0">
      <polygon class="text-gray-300 fill-current" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
  </div>

  <!--  3 card section for actions and description about Newah Society-->
  <section class="pb-20 bg-gray-300 -mt-24">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap">
        <div class="pt-6 w-full md:w-4/12 px-4 text-center">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
            <div class="px-4 py-5 flex-auto">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 m-4 shadow-lg rounded-full bg-yellow-300">
                <i class="fa-sharp fa-solid fa-house"></i>
              </div>
              <h6 class="text-xl font-semibold">BECOME A MEMBER</h6>
              <p class="mt-2 mb-4 text-gray-600">Join us to celebrate, preserve and promote our culture, language and
                festivals together.</p>
            </div>
          </div>
        </div>
        <div class="pt-6 w-full md:w-4/12 px-4 text-center">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
            <div class="px-4 py-5 flex-auto">
              <a href="/events/upcoming-events">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 m-4 shadow-lg rounded-full bg-red-600">
                <i class="fa-solid fa-calendar-days"></i>
              </div>
              <h6 class="text-xl font-semibold">UPCOMING EVENT</h6>
              </a>
              <p class="mt-2 mb-4 text-gray-600">
                Celebrate the festive season with Newah community with our most awaited event <b>"Mhapuja 2024 & Nepal Sambat 1145 New Year Celebration"</b> on <b>Saturday, November 2, 2024</b>
                <br/>
              <a href="/events/upcoming-events" class="font-medium text-blue-600 dark:text-blue-500 hover:underline"> Learn more...</a>
              <div class="text-center mt-2">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLScU0KDGweMHGw2ij22dovk8uxgL5DPOMVFPiEXBoPYJr1ER5A/viewform" target="_blank">
                  <button
                    class="bg-gray-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    style="transition: all 0.15s ease 0s;"
                  >
                    Register Now
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-6 w-full md:w-4/12 px-4 text-center">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
            <div class="px-4 py-5 flex-auto">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 m-4 shadow-lg rounded-full bg-yellow-600">
                <i class="fas fa-retweet"></i>
              </div>
              <h6 class="text-xl font-semibold">SHARE WITH US</h6>
              <p class="mt-2 mb-4 text-gray-600">Take part. Share your knowledge and experience about our culture,
                language and heritage.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap items-center mt-24 pb-16">
        <div class="w-full md:w-5/12 px-4 py-8 mr-auto ml-auto">
          <div
            class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
            <i class="fas fa-user-friends text-xl"></i>
          </div>
          <h3 class="text-3xl mb-2 font-semibold leading-normal">About Newah Society of Calgary</h3>
          <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
            Newah society is an amalgamated group of multiple sub-groups of indigenous people in Nepal identified by a
            common spoken language "Newah Bhay" and similar culture and heritage shared among sub-groups.
          </p>
          <p class="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
            Newah Society of Calgary (NSC) is a non-profit Organization dedicated to preserve, promote, educate and
            transfer Newah language, culture & heritage to the interested members and their descendants.
          </p>
          <a href="/about" class="font-bold text-gray-800 mt-8">Click for more...</a>
        </div>
        <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-red-600">
            <img alt="..." src="./assets/image/medium/lakhey001.png" class="w-full align-middle rounded-t-lg"/>
            <blockquote class="relative p-8 mb-4">
              <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                   class="absolute left-0 w-full block" style="height: 95px; top: -94px;">
                <polygon points="-30,95 583,95 583,65" class="text-red-600 fill-current"></polygon>
              </svg>
              <h4 class="text-xl font-bold text-white">The Newah Civilization</h4>
              <p class="text-md font-light mt-2 text-white">
                Newah people or Newars are the indigenous inhabitants of Kathmandu Valley and its surrounding
                areas in Nepal. Nepal Bhasa (also known as Newah language) is the common language among
                the Newah people. Nepal Bhasa, believed to be developed during Kirat period (circa 12th century),
                was Nepal’s administrative language from the 14th to the late 18th century.
              </p>
              <a href="civilization" class="font-bold text-yellow-400 mt-8">more...</a>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--Community activity-->
  <section class="relative py-8">
    <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
         style="height: 80px; transform: translateZ(0px);">
      <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
           viewBox="0 0 2560 100" x="0" y="0">
        <polygon class="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4 py-8">
      <div class="items-center flex flex-wrap">
        <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-red-600">
            <img alt="..." src="./assets/image/large/people003.jpg" class="w-full align-middle rounded-t-lg"/>
            <blockquote class="relative p-8 mb-4">
              <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                   class="absolute left-0 w-full block" style="height: 95px; top: -94px;">
                <polygon points="-30,95 583,95 583,65" class="text-red-600 fill-current"></polygon>
              </svg>
              <h4 class="text-xl font-bold text-white">Khalha Balha</h4>
              <p class="text-md font-light mt-2 text-white">
                One of the major objectives of NSC is to promote Newah language (Nepal Bhasa). So we have started a new
                online program
                named as "क्यालगरी नेवा: खंल्हा बल्हा" (Calgary Newah Dialogue).
              </p>
              <a href="language" class="font-bold text-yellow-400 mt-8">more...</a>
            </blockquote>
          </div>
        </div>
        <div class="w-full md:w-5/12 ml-auto mr-auto px-4 py-8 ">
          <div class="md:pr-12">
            <div
              class="text-red-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-yellow-500">
              <i class="fas fa-rocket text-xl"></i>
            </div>
            <h3 class="text-3xl font-semibold">Community Activities</h3>
            <p class="mt-4 text-lg leading-relaxed text-gray-600">
              One of the major objectives of NSC is to promote Newah language (Nepal Bhasa). Newah language has been
              classified by UNESCO as a definitely endangered language. If we stopped using this language and stopped
              transmitting it to our new generations, we will have to see the demise of our own language. Hence to
              promote the use of Newah language among us and our new generation, we have started a new online program
              named as "क्यालगरी नेवा: खंल्हा बल्हा" (Calgary Newah Dialogue).
            </p>
            <ul class="list-none mt-6">
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-yellow-400 mr-3"><i
                      class="fa-solid fa-calendar-days"></i></span>
                  </div>
                  <div>
                    <h4 class="text-gray-800 font-semibold">Date and time: Every 1st and 3rd Saturday @ 7:00 pm</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--  Testimonial and images-->
  <section class="relative">
    <div class="flex bg-gray-100 items-center justify-center px-5 pt-40 pb-20">
      <div class="w-full mx-auto rounded-lg bg-white shadow-lg mx-10 px-5 pt-5 pb-10 text-gray-800"
           style="max-width: 800px">
        <div class="w-full pt-1 pb-5">
          <div class="overflow-hidden rounded-full w-40 h-40 -mt-28 mx-auto shadow-lg">
            <img src="assets/image/people/hemanta.JPG" width="180" alt="">
          </div>
        </div>
        <div class="w-full mb-10">
          <p class="text-6xl text-gray-300 text-left leading-tight h-3 pl-4">“</p>
          <p class="text-sm text-gray-600 text-center px-10 text-justify">Our mission is to preserve and promote Newah
            culture and language, with active support from our community, fostering cultural awareness and unity within
            Calgary's diverse environment.
          </p>
          <p class="text-6xl text-gray-300 text-right leading-tight h-3 -mt-3 pr-4">”</p>
        </div>
        <div class="w-full">
          <p class="text-md text-gray-900 font-semibold text-center">Hemanta Maharjan</p>
          <p class="text-sm text-gray-500 text-center">President (2023-2025)</p>
        </div>
      </div>
    </div>
    <div class="container mx-auto px-4 py-20 ">
      <div class="container text-center mb-8 lg:mb-0">
        <h2 class="text-3xl font-semibold">Glimpses of Newah Heritage and Lifestyle</h2>
      </div>
      <div class="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="grid gap-6 row-gap-5 mb-8 lg:grid-cols-4 sm:row-gap-6 sm:grid-cols-2">
          <a aria-label="View Item">
            <div
              class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <img class="object-cover w-full h-56 md:h-64 xl:h-80"
                   src="https://images.pexels.com/photos/2104882/pexels-photo-2104882.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500"
                   alt=""/>
<!--              <div-->
<!--                class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">-->
<!--                <p class="mb-4 text-lg font-bold text-gray-100">Mona Lisa</p>-->
<!--                <p class="text-sm tracking-wide text-gray-300">-->
<!--                  Painted between 1503 and 1517, Da Vinci’s alluring portrait has been dogged by two questions since the-->
<!--                  day it was made: Who’s the subject and why is she smiling?-->
<!--                </p>-->
<!--              </div>-->
            </div>
          </a>
          <a aria-label="View Item">
            <div
              class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <img class="object-cover w-full h-56 md:h-64 xl:h-80"
                   src="https://images.pexels.com/photos/4133938/pexels-photo-4133938.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                   alt=""/>
<!--              <div-->
<!--                class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">-->
<!--                <p class="mb-4 text-lg font-bold text-gray-100">The Starry Night</p>-->
<!--                <p class="text-sm tracking-wide text-gray-300">-->
<!--                  Vincent Van Gogh’s most popular painting, The Starry Night was created by Van Gogh at the asylum in-->
<!--                  Saint-Rémy, where he’d committed himself in 1889.-->
<!--                </p>-->
<!--              </div>-->
            </div>
          </a>
          <a aria-label="View Item">
            <div
              class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <img class="object-cover w-full h-56 md:h-64 xl:h-80"
                   src="https://images.pexels.com/photos/4385529/pexels-photo-4385529.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                   alt=""/>
<!--              <div-->
<!--                class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">-->
<!--                <p class="mb-4 text-lg font-bold text-gray-100">The Kiss</p>-->
<!--                <p class="text-sm tracking-wide text-gray-300">-->
<!--                  Opulently gilded and extravagantly patterned, The Kiss, Gustav Klimt’s fin-de-siècle portrayal of-->
<!--                  intimacy, is a mix of Symbolism and Vienna Jugendstil, the Austrian variant of Art Nouveau.-->
<!--                </p>-->
<!--              </div>-->
            </div>
          </a>
          <a aria-label="View Item">
            <div
              class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <img class="object-cover w-full h-56 md:h-64 xl:h-80"
                   src="https://images.pexels.com/photos/6102867/pexels-photo-6102867.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                   alt=""/>
<!--              <div-->
<!--                class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">-->
<!--                <p class="mb-4 text-lg font-bold text-gray-100">The Harvesters</p>-->
<!--                <p class="text-sm tracking-wide text-gray-300">-->
<!--                  Bruegel’s fanfare for the common man is considered one of the defining works of Western art. This-->
<!--                  composition was one of six created on the theme of the seasons.-->
<!--                </p>-->
<!--              </div>-->
            </div>
          </a>
        </div>
        <div class="grid gap-6 row-gap-5 mb-8 lg:grid-cols-4 sm:row-gap-6 sm:grid-cols-2">
          <a aria-label="View Item">
            <div
              class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <img class="object-cover w-full h-56 md:h-64 xl:h-80"
                   src="https://images.pexels.com/photos/2177708/pexels-photo-2177708.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500"
                   alt=""/>
<!--              <div-->
<!--                class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">-->
<!--                <p class="mb-4 text-lg font-bold text-gray-100">Mona Lisa</p>-->
<!--                <p class="text-sm tracking-wide text-gray-300">-->
<!--                  Painted between 1503 and 1517, Da Vinci’s alluring portrait has been dogged by two questions since the-->
<!--                  day it was made: Who’s the subject and why is she smiling?-->
<!--                </p>-->
<!--              </div>-->
            </div>
          </a>
          <a aria-label="View Item">
            <div
              class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <img class="object-cover w-full h-56 md:h-64 xl:h-80"
                   src="https://images.pexels.com/photos/6026424/pexels-photo-6026424.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                   alt=""/>
<!--              <div-->
<!--                class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">-->
<!--                <p class="mb-4 text-lg font-bold text-gray-100">The Starry Night</p>-->
<!--                <p class="text-sm tracking-wide text-gray-300">-->
<!--                  Vincent Van Gogh’s most popular painting, The Starry Night was created by Van Gogh at the asylum in-->
<!--                  Saint-Rémy, where he’d committed himself in 1889.-->
<!--                </p>-->
<!--              </div>-->
            </div>
          </a>
          <a aria-label="View Item">
            <div
              class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <img class="object-cover w-full h-56 md:h-64 xl:h-80"
                   src="https://images.pexels.com/photos/4028822/pexels-photo-4028822.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                   alt=""/>
<!--              <div-->
<!--                class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">-->
<!--                <p class="mb-4 text-lg font-bold text-gray-100">The Kiss</p>-->
<!--                <p class="text-sm tracking-wide text-gray-300">-->
<!--                  Opulently gilded and extravagantly patterned, The Kiss, Gustav Klimt’s fin-de-siècle portrayal of-->
<!--                  intimacy, is a mix of Symbolism and Vienna Jugendstil, the Austrian variant of Art Nouveau.-->
<!--                </p>-->
<!--              </div>-->
            </div>
          </a>
          <a aria-label="View Item">
            <div
              class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <img class="object-cover w-full h-56 md:h-64 xl:h-80"
                   src="https://images.pexels.com/photos/4122862/pexels-photo-4122862.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                   alt=""/>
<!--              <div-->
<!--                class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">-->
<!--                <p class="mb-4 text-lg font-bold text-gray-100">The Harvesters</p>-->
<!--                <p class="text-sm tracking-wide text-gray-300">-->
<!--                  Bruegel’s fanfare for the common man is considered one of the defining works of Western art. This-->
<!--                  composition was one of six created on the theme of the seasons.-->
<!--                </p>-->
<!--              </div>-->
            </div>
          </a>
        </div>
        <!--        <div class="text-center">-->
        <!--          <a href="/" aria-label="" class="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">-->
        <!--            View gallery-->
        <!--            <svg class="inline-block w-3 ml-2" fill="currentColor" viewBox="0 0 12 12">-->
        <!--              <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z"></path>-->
        <!--            </svg>-->
        <!--          </a>-->
        <!--        </div>-->
      </div>
    </div>
  </section>

  <!--  Contact Us-->
  <app-contact></app-contact>
</main>
