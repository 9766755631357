import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './component/home/home.component';
import { NavComponent } from './component/layout/nav/nav.component';
import { FooterComponent } from './component/layout/footer/footer.component';
import { AboutComponent } from './component/about/about.component';
import { ContactComponent } from './component/contact/contact.component';
import { PhotosComponent } from './component/gallery/photos/photos.component';
import { VideosComponent } from './component/gallery/videos/videos.component';
import { MembershipComponent } from './component/membership/membership.component';
import { BylawComponent } from './component/bylaw/bylaw.component';
import { VolunteerComponent } from './component/volunteer/volunteer.component';
import { DonateComponent } from './component/donate/donate.component';
import { CivilizationComponent } from './component/civilization/civilization.component';
import { NationComponent } from './component/nation/nation.component';
import { LanguageComponent } from './component/language/language.component';
import { PublicationsComponent } from './component/publications/publications.component';
import { ObjectivesComponent } from './component/footer/objectives/objectives.component';
import { BylawsComponent } from './component/footer/bylaws/bylaws.component';
import { LifemembersComponent } from './component/footer/lifemembers/lifemembers.component';
import { GeneralmembersComponent } from './component/footer/generalmembers/generalmembers.component';
import { SupportComponent } from './component/footer/support/support.component';
import { WordsfrompresidentComponent } from './component/footer/wordsfrompresident/wordsfrompresident.component';
import { UpcomingEventsComponent } from './component/events/upcoming-events/upcoming-events.component';
import { PastEventsComponent } from './component/events/past-events/past-events.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    PhotosComponent,
    VideosComponent,
    MembershipComponent,
    BylawComponent,
    VolunteerComponent,
    DonateComponent,
    CivilizationComponent,
    NationComponent,
    LanguageComponent,
    PublicationsComponent,
    ObjectivesComponent,
    BylawsComponent,
    LifemembersComponent,
    GeneralmembersComponent,
    SupportComponent,
    WordsfrompresidentComponent,
    UpcomingEventsComponent,
    PastEventsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
